<ion-menu contentId="menu-content">
    <ion-header class="{{this.isDevelopment() ? 'development' : ''}} ion-no-border">
        <ion-toolbar>
            <ion-title class="menu-title">{{ 'menu.title.long' | translate }}</ion-title>
            <ion-note class="loginName" slot="end">{{this.userStore.firstName()}}</ion-note>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-no-border">
        <ion-list>
            <ion-item lines="none">
                <img alt="" class="cloudotdr-icon-small" src="../assets/icon/SVG/home.svg"/>
                <ion-label class="showAsLink ion-margin-start" routerLink="/home" (click)="closeMenu()">{{ 'menu.home.short' | translate }}</ion-label>
            </ion-item>
            <ion-item-divider />
            <ion-item lines="none">
                <img alt="" class="cloudotdr-icon-small" src="../assets/icon/SVG/account.svg"/>
                <ion-label class="showAsLink ion-margin-start" routerLink="/account/my-account" (click)="closeMenu()">{{ 'menu.account.short' | translate }}</ion-label>
            </ion-item>
            <ion-item-divider />
            @if(isNotNullOrEmpty(this.deploymentStore.deploymentList())){
              <ion-item lines="none">
                <img alt="" class="cloudotdr-icon-small" src="../assets/icon/SVG/connectorcheck.svg"/>
                <ion-label class="showAsLink ion-margin-start" routerLink="/port-mappings" (click)="closeMenu()">{{ 'menu.popMapping.short' | translate }}</ion-label>
              </ion-item>
            }
            <div>
                <ion-item-divider />
                <ion-item class="showAsLink" lines="none" (click)="presentTenantDeploymentModal();closeMenu()">
                    <img alt="" class="cloudotdr-icon-small" src="../assets/icon/SVG/briefcase.svg"/>
                    <ion-label class=" ion-margin-start"><sub>{{(this.deploymentStore.selectedTenant()?.name ?? 'Click here to set your deployment')}}</sub> <br /> {{(this.deploymentStore.selectedDeployment()?.name)}}</ion-label>
                </ion-item>
            </div>
        </ion-list>
    </ion-content>
    <ion-footer class="showAsLink footer-logout background-cloudotdr" (click)="logOut();closeMenu()">
        <ion-item class="background-cloudotdr" lines="none" >
            <ion-icon class="background-cloudotdr" color="primary" name="log-out-outline" slot="start" />
            <ion-label class="background-cloudotdr">{{ 'menu.logout.short' | translate }}</ion-label>
        </ion-item>

    </ion-footer>
</ion-menu>
<ion-router-outlet id="menu-content" />
@if(this.userStore.isAuthenticated()) {
  <ion-tabs>
      <ion-tab-bar class=" border-top-cloudotdr" slot="bottom">
          <ion-tab-button tab="home" [class.active-item]="selectedAction === 'home'">
              <img alt="" class="cloudotdr-icon-large" src="../assets/icon/SVG/home.svg"/>
              <ion-label>{{ 'menu.home.short' | translate }}</ion-label>
          </ion-tab-button>

          @if(this.deploymentStore.tenantFeatureList().scan) {
            <ion-tab-button [class.active-item]="selectedAction === scanTypeEnum.Scan.toString()"
                            [disabled]="isNullOrEmpty(this.deploymentStore.selectedDeploymentId())"
                            tab="action/{{scanTypeEnum.Scan}}">
                <img alt="" class="cloudotdr-icon-large" src="../assets/icon/SVG/scan.svg"/>
                <ion-label>{{ 'menu.scan.short' | translate }}</ion-label>
            </ion-tab-button>
          }

          @if(this.deploymentStore.tenantFeatureList().vfl) {
            <ion-tab-button [class.active-item]="selectedAction === scanTypeEnum.VisualFaultLocator.toString()"
                            [disabled]="isNullOrEmpty(this.deploymentStore.selectedDeploymentId())"
                            tab="action/{{scanTypeEnum.VisualFaultLocator}}">
                <img alt="" class="cloudotdr-icon-large" src="../assets/icon/SVG/vfl.svg"/>
                <ion-label>{{ 'menu.laser.short' | translate }}</ion-label>
            </ion-tab-button>
          }

        @if(this.deploymentStore.tenantFeatureList().history) {
          <ion-tab-button tab="history"
                          [class.active-item]="selectedAction === 'history'"
                          [disabled]="isNullOrEmpty(this.deploymentStore.selectedDeploymentId())">
              <img alt="" class="cloudotdr-icon-large" src="../assets/icon/SVG/scan-report.svg"/>
            @if(this.notificationStore.actionHistoryUnreadMessages()) {
              <ion-badge class="notification-badge" color="danger" />
            }
              <ion-label>{{ 'menu.history.short' | translate }}</ion-label>
          </ion-tab-button>
        }
      </ion-tab-bar>
  </ion-tabs>
}

