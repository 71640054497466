import { HttpRequest } from '@angular/common/http';
import { computed } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { storagePrefix } from '@/shared/lib/constants/storage.const';
import { urlTranslationList } from '@/shared/lib/constants/translated-urls';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';

import {
  AppLoadingNotification,
  AppLoadingNotificationItem,
} from '../../../../app/src/app/interfaces/app/app-loading-notification';
import { VersionInfo } from '../../../../app/src/app/interfaces/app/version-info';
import { environment } from '../../../../app/src/environments/environment';

type NotificationState = {
  notificationData: AppLoadingNotification | null
  versionInfo: VersionInfo | null;
  actionHistoryUnreadMessages: boolean;
  actionConnectorCheckUnreadMessages: boolean;
  actionMonitoringUnreadMessages: boolean;
  signalRConnectionState: number;
};

const initialState: NotificationState = {
  notificationData: null,
  versionInfo: null,
  actionHistoryUnreadMessages: false,
  actionConnectorCheckUnreadMessages: false,
  actionMonitoringUnreadMessages: false,
  signalRConnectionState: 0
};

export const NotificationStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => {
    return {
      isLoading: computed(() => (store.notificationData()?.notificationList?.length ?? 0) > 0 && store.notificationData()?.notificationList?.some((url) => !url.isLoaded)),
    };
  }),
  withMethods((store) => ({
    setLoadingState(request: HttpRequest<any>, isLoaded: boolean) {
      const splitRequest = request.url.replace(environment.data.baseUrl, '').replace(environment.identity.baseUrl, '').split('/')[1];
      const newNotificationItem: AppLoadingNotificationItem = {
        isLoaded: isLoaded,
        description: urlTranslationList.find(f => f.request.toLowerCase() === splitRequest.toLowerCase())?.description ?? `Generic: ${splitRequest}`,
        showItem: true,
      };

      let currentList = store.notificationData()?.notificationList;
      if (isNotNullOrEmpty(currentList)) {
        const index = currentList?.findIndex((f: AppLoadingNotificationItem) => f.description === newNotificationItem.description);
        if (index !== undefined && index > -1) {
          currentList[index] = newNotificationItem;
        } else {
          currentList.push(newNotificationItem);
        }
      } else {
        currentList = [newNotificationItem];
      }

      if (currentList?.some((f: AppLoadingNotificationItem) => !f.isLoaded)) {
        patchState(store, { notificationData: { notificationList: currentList } });
      } else {
        patchState(store, { notificationData: null });
      }
    },
    setLastVersionInfo(versionInfo: VersionInfo) {
      versionInfo.lastChecked = new Date();
      patchState(store, { versionInfo });
    },
    userVersionAlreadyNotified(versionInfo: VersionInfo) {
      return store.versionInfo()?.major === versionInfo.major && store.versionInfo()?.minor === versionInfo.minor && store.versionInfo()?.patch === versionInfo.patch;
    },
    setActionUnread(data: string) {
      switch (data.toLowerCase()) {
        case 'connectorcheck':
          patchState(store, { actionConnectorCheckUnreadMessages: true });
          break;
        case 'monitoringstart':
          patchState(store, { actionMonitoringUnreadMessages: true });
          break;
        case 'monitoringstop':
          patchState(store, { actionMonitoringUnreadMessages: true });
          break;
        case 'monitoringpause':
          patchState(store, { actionMonitoringUnreadMessages: true });
          break;
        case 'monitoringresume':
          patchState(store, { actionMonitoringUnreadMessages: true });
          break;
        case 'monitoringalertreset':
          patchState(store, { actionMonitoringUnreadMessages: true });
          break;
      }

      patchState(store, { actionHistoryUnreadMessages: true });
    },
    setActionRead(data: string) {
      switch (data.toLowerCase()) {
        case 'connectorcheck':
          patchState(store, { actionConnectorCheckUnreadMessages: false });
          break;
        case 'monitoring':
          patchState(store, { actionMonitoringUnreadMessages: false });
          break;
        case 'history':
          patchState(store, { actionHistoryUnreadMessages: false });
          break;
      }
    },
    clear() {
      patchState(store, initialState);
    },
    setSignalRConnectionState(state: number) {
      patchState(store, { signalRConnectionState: state });
    }
  })),
  withStorageSync({
    key: `${storagePrefix}_notifications`,
    storage: () => sessionStorage,
  }),
);
