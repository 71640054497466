import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { ODSRoute } from '@/shared/lib/interfaces/ods-route';
import { UserStore } from '@/shared/lib/stores/user.store';

const homePage: ODSRoute[] = [
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    data: {
      unProtected: false,
    },
  },
  {
    path: '',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    data: {
      unProtected: false,
    },
  },
]

const accountPages: ODSRoute[] = [
  {
    path: 'account/login',
    loadComponent: () => import('./pages/account/login/login.page').then(m => m.LoginPage),
    data: {
      unProtected: true,
    },
  },
  {
    path: 'account/forgot-credentials',
    loadComponent: () => import('./pages/account/forgot-password/forgot-password.page').then(m => m.ForgotPasswordPage),
    data: {
      unProtected: true,
    },
  },
  {
    path: 'account/my-account',
    loadComponent: () => import('./pages/account/my-account/my-account.page').then(m => m.MyAccountPage),
    data: {
      unProtected: false,
    },
  },
  {
    path: 'account/preferences',
    loadComponent: () => import('./pages/account/preferences/preferences.page').then(m => m.PreferencesPage),
    data: {
      unProtected: false,
    },
  },
]

const supportPages: ODSRoute[] = [
  {
    path: 'support/contact',
    loadComponent: () => import('./pages/support/contact/contact.page').then(m => m.ContactPage),
    data: {
      unProtected: false,
    },
  },
  {
    path: 'support/device-info',
    loadComponent: () => import('./pages/support/device-info/device-info.page').then(m => m.DeviceInfoPage),
    data: {
      unProtected: false,
    },
  },
  {
    path: 'support/how-to',
    loadComponent: () => import('./pages/support/how-to/how-to.page').then(m => m.HowToPage),
    data: {
      unProtected: false,
    },
  },
]

const analyzerPages: ODSRoute[] = [
  {
    path: 'sor-analyzer',
    loadComponent: () => import('./pages/analyzer/sor-analyzer/sor-analyzer.page').then(m => m.SorAnalyzerPage),
    data: {
      unProtected: false,
    },
  },
]

const scanPages: ODSRoute[] = [
  {
    path: 'scan-details',
    loadComponent: () => import('./pages/scan/scan-details/scan-details.page').then(m => m.ScanDetailsPage),
    data: {
      unProtected: false,
    },
  },
  {
    path: 'action/:scanType',
    loadComponent: () => import('./pages/scan/action/action.page').then(m => m.ActionPage),
    data: {
      unProtected: false,
    },
  },
  {
    path: 'history',
    loadComponent: () => import('./pages/scan/history/history.page').then(m => m.HistoryPage),
    data: {
      unProtected: false,
    },
  },
  {
    path: 'connector-check-results',
    loadComponent: () => import('./pages/scan/connector-check-result/connector-check-result.page').then(m => m.ConnectorCheckResultPage),
    data: {
      unProtected: false,
    },
  },
  {
    path: 'port-mappings',
    loadComponent: () => import('./pages/scan/port-mappings/port-mappings.page').then(m => m.PortMappingsPage),
    data: {
      unProtected: false,
    },
  },
];

const monitoringPages: ODSRoute[] = [
  {
    path: 'monitoring/:scanType',
    loadComponent: () => import('./pages/monitoring/monitoring/monitoring.page').then(m => m.MonitoringPage),
    data: {
      unProtected: false,
    },
  },
  {
    path: 'monitoring-results',
    loadComponent: () => import('./pages/monitoring/monitoring-result/monitoring-result.page').then(m => m.MonitoringResultPage),
    data: {
      unProtected: false,
    },
  },
]

const errorPages: ODSRoute[] = [
  // {
  //   path: '**',
  //   loadComponent: () => import('./pages/error/error.component').then((c) => c.ErrorComponent),
  //   data: {
  //     unProtected: true,
  //     permission: '',
  //   },
  // },
];


export const routes: ODSRoute[] = [
  ...accountPages,
  ...analyzerPages,
  ...homePage,
  ...monitoringPages,
  ...scanPages,
  ...supportPages,
  ...errorPages,
].map((page) => {
  if (page.redirectTo) {
    return {
      ...page,
      pathMatch: page.pathMatch ?? 'full',
    };
  } else {
    return {
      ...page,
      canActivate: [
        () => {
          const unProtected = page.data.unProtected;
          const router = inject(Router);
          const userStore = inject(UserStore);
          const isAuthenticated = userStore.isAuthenticated();

          // if unProtected, allow the route
          if (unProtected) {
            return true;
          }

          if (!isAuthenticated) {
            router.navigate(['/account/login']);
            return false;
          }

          return true;
        },
      ],
    };
  }
});

