import { Injectable } from '@angular/core';

import { environment } from '../../../../../app/src/environments/environment';


@Injectable({
  providedIn: 'root',
})

export class MessageService {
    messages: string[] = [];

    add(objectName: string, message: string) {
        if (!environment.production) {
            console.log(objectName, message);
        }
        this.messages.push(message);
    }

    warn(objectName: string, object: any) {
        if (!environment.production) {
            console.warn(objectName, object);
        }
    }

    error(objectName: string, object: any) {
        if (!environment.production) {
            console.error(objectName, object);
        }
    }

    table(object: any) {
        if (!environment.production) {
            console.table(object);
        }
    }

    clear() {
        this.messages = [];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    notify(objectName: string, object: any): void {
      return;
    }
}
