import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Deployment } from '@/app/interfaces/api/deployment';
import { DeploymentEdit } from '@/app/interfaces/api/deployment-edit';
import { DeploymentNotifications } from '@/app/interfaces/api/deployment-notification';
import { DeploymentRemoteSetting } from '@/app/interfaces/api/deployment-remote-setting';
import { environment } from '@/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeploymentsApi {
  http = inject(HttpClient);

  public createDeployment(deploymentData: DeploymentEdit): Observable<DeploymentEdit> {
    return this.http.post<DeploymentEdit>(`${environment.data.baseUrl}/deployments`, deploymentData);
  }

  public editDeployment(deploymentData: DeploymentEdit, deploymentId: number): Observable<void> {
    return this.http.put<void>(`${environment.data.baseUrl}/deployments/${deploymentId}`, deploymentData);
  }

  public deleteDeployment(deploymentId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.data.baseUrl}/deployments/${deploymentId}`);
  }

  public setRemoteDeploymentStatus(deploymentData: DeploymentRemoteSetting, deploymentId: number): Observable<void> {
    return this.http.put<void>(`${environment.data.baseUrl}/deployments/remote/${deploymentId}`, deploymentData);
  }

  public getDeploymentTree(tenant: number): Observable<Deployment[]> {
    return this.http.get<Deployment[]>(`${environment.data.baseUrl}/deployments/tree/${tenant}`);
  }

  public changeUserNotifications(notificationData: DeploymentNotifications): Observable<void> {
    return this.http.put<void>(`${environment.data.baseUrl}/deploymentnotifications/${notificationData.deploymentId}`, notificationData);
  }

  public getDeploymentDataFromApi(tenant: number): Observable<Deployment[]> {
    return this.http.get<Deployment[]>(`${environment.data.baseUrl}/deployments/extended`, { params: { tenantId: `${tenant}`, includeDeploymentsWithoutDevices: false } });
  }

  public getUserNotifications(deploymentId: number): Observable<DeploymentNotifications[]> {
    return this.http.get<DeploymentNotifications[]>(`${environment.data.baseUrl}/deploymentnotifications/${deploymentId}`);
  }

}
