import { computed } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { protectedUsers } from '@/shared/lib/constants/protected-users';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';

import { Configuration } from '../../../../app/src/app/interfaces/api/configuration';
import { storagePrefix } from '../constants/storage.const';
import { AuthToken } from '../interfaces/auth-token';
import { AuthTokenDetails } from '../interfaces/auth-token-details';

type UserState = {
  isLoading: boolean;
  userProfile: AuthTokenDetails | null;
  token: AuthToken | null;
  displayLanguage: string;
  preferences: Configuration | null;
};

const initialState: UserState = {
  isLoading: false,
  userProfile: null,
  token: null,
  displayLanguage: 'en',
  preferences: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => {
    return {
      firstName: computed(() => {
        return store.userProfile()?.name;
      }),
      isAdmin: computed(() => false),
      isAuthenticated: computed(() => {
        if (isNotNullOrEmpty(store.token()?.token)) {
          return new Date(store.token()!.expiration) > new Date();
        } else {
          return false;
        }
      }),
      isGlobalAdmin: computed(() => {
        if (isNotNullOrEmpty(store.userProfile()?.sub)) {
          return isNotNullOrEmpty(protectedUsers.find(f => f === store.userProfile()!.sub)) || store.userProfile()!.roles.map((role: string) => role.toLowerCase()).includes('globaladmin');
        } else {
          return false;
        }
      }),
      userRoles: computed(() => {return store.userProfile()?.roles || [];}),
    };
  }),
  withMethods((store) => ({
    setLoadingState(loadingState: boolean) {
      patchState(store, { isLoading: loadingState });
    },
    setAuthToken(authToken: AuthToken) {
      if (isNotNullOrEmpty(authToken?.token)) {
        const jwtData = authToken.token.split('.')[1];
        const decodedJwtJsonData = window.atob(jwtData);
        const decodedJwtData = JSON.parse(decodedJwtJsonData);

        patchState(store, {
          token: authToken,
          userProfile: {
            roles: decodedJwtData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
            email: decodedJwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
            name: decodedJwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
            identifier: decodedJwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
            sub: decodedJwtData.sub
          }
        });
      } else {
        patchState(store, { userProfile: null });
      }
    },
    hasUserRole(role: string) {
      return store.userRoles().map((role: string) => role.toLowerCase()).includes(role.toLowerCase()) || store.isGlobalAdmin();
    },
    clear() {
      patchState(store, { token: null, userProfile: null });
    },
    setDisplayLanguage(language: string) {
      patchState(store, { displayLanguage: language });
    },
    setPreferences(preferences: Configuration) {
      patchState(store, { preferences });
    }
  })),
  withStorageSync({
    key: `${storagePrefix}_user`,
    storage: () => localStorage,
  })
);
