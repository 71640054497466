import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';

import { apiEndpoint } from '../../constants/endpoints.const';
import { Configuration } from '../../interfaces/api/configuration';

@Injectable({
    providedIn: 'root'
})
export class PreferencesApi {
    http = inject(HttpClient);

    public getPreferencesFromApi(tenant: number): Observable<Configuration> {
        return this.http.get<Configuration>(`${apiEndpoint}/users/preferences/byheader/${tenant}`).pipe(tap(data => {
            if (isNotNullOrEmpty(data)) {
                data.iorConfiguration = JSON.parse(data.ior ?? '');
                data.ior = data.iorConfiguration.find(f => f.Name === data.waveLength?.toString())?.VALUE ?? '';
            }
        }) );
    }
}
