<ion-header>
  @if (this.searchData() && this.searchData()?.length! > 5) {
    <ion-toolbar>
      <ion-searchbar (ionInput)="searchbarInput($event)" />
    </ion-toolbar>
  }
</ion-header>

<ion-content class="ion-padding">
  <ion-list [inset]="true">
    @for (item of filteredItems(); track item) {
      <ion-item (click)="setSelection(item.id)" class="showAsLink">
        [{{ item.id }}] {{ item.name }}
      </ion-item>
    } @empty {
      <ion-item>
        No
        @if (isTenantSelection) {
          tenants
        } @else {
          deployments
        }
        available to select
      </ion-item>
    }
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancelChanges()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-footer>
