import { computed, effect, inject } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { getState, patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';

import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { EnabledFeatures } from '@/shared/lib/interfaces/enabled-features';
import { UserStore } from '@/shared/lib/stores/user.store';

import { ScanType } from '../../../../app/src/app/constants/scan-type.enum';
import { Deployment } from '../../../../app/src/app/interfaces/api/deployment';
import { Tenant } from '../../../../app/src/app/interfaces/api/tenant';
import { DeploymentsApi } from '../../../../app/src/app/services/api/deployments.api';
import { DevicesApi } from '../../../../app/src/app/services/api/devices.api';
import { PreferencesApi } from '../../../../app/src/app/services/api/preferences.api';
import { SignalRService } from '../../../../app/src/app/services/communications/signal-r.service';
import { storagePrefix } from '../constants/storage.const';

type DeploymentState = {
  selectedTenant: Tenant | null;
  tenantList: Tenant[] | null;
  selectedDeployment: Deployment | null;
  deploymentList: Deployment[] | null;
  deploymentIsLoading: boolean;
};

const initialState: DeploymentState = {
  selectedTenant: null,
  tenantList: [],
  selectedDeployment: null,
  deploymentList: [],
  deploymentIsLoading: false,
};

export const DeploymentStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => {
    return {
      selectedTenantId: computed(() => {
        return store.selectedTenant()?.id;
      }),
      selectedDeploymentId: computed(() => {
        return store.selectedDeployment()?.id;
      }),
      tenantFeatureList: computed(() => {
        const tenantFeature: EnabledFeatures =
          {
            scan: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'scan') ?? false,
            connectorCheck: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'connectorcheck') ?? false,
            history: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'history') ?? false,
            aux: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'aux') ?? false,
            vfl: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'vfl') ?? false,
            monitoring: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'monitoring') ?? false,
            sorConverter: store.selectedTenant()?.featuresList?.some((s: string) => s.toLowerCase() === 'sorconverter') ?? false,
          };
        return tenantFeature;
      }),
      deploymentFiberList: computed(() => {
        return store.selectedDeployment()?.deploymentPortMappings ?? [];
      }),
      deploymentLaunchFiberBundleArray: computed(() => {
        const launchFiberArray: any[] = [];

        store.selectedDeployment()?.deploymentPortMappings?.forEach((fiber: any) => {
          const launchFiber = Math.ceil(fiber.fiberNumber / 12);
          if (!launchFiberArray.find(f => f === launchFiber)) {
            launchFiberArray.push(Math.ceil(fiber.fiberNumber / 12));
          }
        });

        return launchFiberArray.sort((a, b) => a > b ? 1 : -1);
      }),
    };
  }),
  withComputed((store) => ({
    scanTypeList: computed<any[]>
    (() => {
      const scanTypeEnum: typeof ScanType = ScanType;
      const features: EnabledFeatures = store.tenantFeatureList();
      if (isNotNullOrEmpty(features)) {

        const scanTypeList: any[] = [];
        const enumList = Object.values(ScanType).filter(value => typeof value === 'number');
        for (const option of enumList) {
          if (Number(option) === scanTypeEnum.Scan && features.scan) {
            scanTypeList.push(option);
          }
          if (Number(option) === scanTypeEnum.VisualFaultLocator && features.vfl) {
            scanTypeList.push(option);
          }
          if (Number(option) === scanTypeEnum.Aux && features.aux) {
            scanTypeList.push(option);
          }
          if (Number(option) === scanTypeEnum.MonitoringStart && features.monitoring) {
            scanTypeList.push(option);
          }
          if (Number(option) === scanTypeEnum.ConnectorCheck && features.connectorCheck) {
            scanTypeList.push(option);
          }
        }

        return scanTypeList;
      }
      return [];
    }),
  })),
  withMethods((store, deploymentsApi = inject(DeploymentsApi), userStore = inject(UserStore), preferencesApi = inject(PreferencesApi), devicesApi = inject(DevicesApi), signalRService = inject(SignalRService)) => ({
    setTenantList(tenantList: Tenant[]) {
      patchState(store, { tenantList });
      if (tenantList.length === 1) {
        this.setTenant(tenantList[0].id);
      }
    },
    setTenant(tenant?: number) {
      if (isNotNullOrEmpty(tenant)) {
        if (store.selectedTenantId() !== tenant) {
          patchState(store, {
            selectedTenant: store.tenantList()?.find((t) => t.id === tenant),
            selectedDeployment: null,
            deploymentList: [],
          });
          patchState(store, { deploymentIsLoading: true });
          deploymentsApi.getDeploymentDataFromApi(tenant).subscribe((deployments: any) => {
            this.setDeploymentList(deployments);
          });
          preferencesApi.getPreferencesFromApi(tenant).subscribe((preferences) => {
            userStore.setPreferences(preferences);
          });
        }
      } else {
        patchState(store, { selectedTenant: null, selectedDeployment: null, deploymentList: [] });
      }
    },
    refreshDeploymentList() {
      if (isNotNullOrEmpty(store.selectedTenantId())) {
        deploymentsApi.getDeploymentTree(store.selectedTenantId()!).subscribe((deployments: any) => {
          this.setDeploymentList(deployments);
        });
      }
    },
    setDeploymentList(deploymentList?: Deployment[]) {
      patchState(store, { deploymentList: deploymentList });
      patchState(store, { deploymentIsLoading: false });
      if (deploymentList?.length === 1) {
        this.setDeployment(deploymentList[0].id);
      } else if (isNotNullOrEmpty(store.selectedDeploymentId())) {
        this.setDeployment(store.selectedDeploymentId());
      }
    },
    setDeployment(deployment?: number) {
      if (isNotNullOrEmpty(deployment)) {
        if (store.selectedDeploymentId() !== deployment) {
          const deploymentFromList = store.deploymentList()?.find((d) => d.id === deployment);
          if (isNotNullOrEmpty(deploymentFromList)) {
            devicesApi.getDeviceConfigurationsByDeploymentId(deploymentFromList.id).subscribe((deviceConfiguration: any) => {
              deploymentFromList.deviceThresholds = deviceConfiguration;
              patchState(store, { selectedDeployment: deploymentFromList });
              this.subscribeSignalR();
            });
          }
        }
      } else {
        patchState(store, { selectedDeployment: null });
      }
    },
    clear() {
      patchState(store, { selectedDeployment: null, selectedTenant: null, tenantList: [], deploymentList: [] });
    },
    subscribeSignalR() {
      if (isNotNullOrEmpty(store.selectedDeploymentId())) {
        signalRService.unsubscribeActiveHubs();
        signalRService.subscribeToDeploymentHub(store.selectedDeploymentId()!);
      }
    },
  })),
  withHooks({
    onInit(store) {
      const signalRService = inject(SignalRService);
      effect(() => {
        const state = getState(store);
        if (isNotNullOrEmpty(state.selectedDeployment?.id)) {
          signalRService.initialize().then(() => store.subscribeSignalR());
        }
      }, { allowSignalWrites: true });
    },
  }),
  withStorageSync({
    key: `${storagePrefix}_mobile_deployment`,
    storage: () => localStorage,
  }),
);
