import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { apiEndpoint } from '@/app/constants/endpoints.const';
import { Device } from '@/app/interfaces/api/device';
import { DeviceConfiguration } from '@/app/interfaces/api/device-configuration';
import { Switch } from '@/app/interfaces/api/switch';
import { SwitchEdit } from '@/app/interfaces/api/switch-edit';
import { environment } from '@/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevicesApi {
  http = inject(HttpClient);

  public createDevice(deviceData: any): any {
    const deviceDto = {
      customId: deviceData.name,
      iotHubDeviceId: deviceData.name,
      name: deviceData.name,
    };

    // create device
    return this.http.post<Device>(`${environment.data.baseUrl}/devices`, deviceDto)
      .pipe(map(device => {
        deviceData.id = device.id;
        deviceData.deviceId = device.id;

        // create configuration
        this.editDevice(deviceData, device.id)
          .subscribe(() => {
          });

        // create iothub
        this.http.post<any>(`${environment.data.baseUrl}/iothubs`, { deviceId: deviceDto.name })
          .subscribe(() => {
          });

        return device;
      }));
  }

  public editDevice(deviceData: any, deviceId: number): Observable<void> {
    return this.http.put<void>(`${environment.data.baseUrl}/devices/${deviceId}`, deviceData);
  }

  public assignDevice(deviceId: number, tenantId?: number): Observable<void> {
    return this.http.put<void>(`${environment.data.baseUrl}/devices/assign/${deviceId}`, { tenantId });
  }

  public purgeDevice(deviceId: string): Observable<object> {
    return this.http.post(`${environment.data.baseUrl}/iothubs/${deviceId}/purge`, {});
  }

  public removeDevice(deviceId: number, softDelete: boolean): Observable<object> {
    return this.http.delete(`${environment.data.baseUrl}/devices/${deviceId}?softDelete=${softDelete}`);
  }

  public downloadConfig(iotHubDeviceId: string): Observable<any> {
    return this.http.get(`${environment.data.baseUrl}/iothubs/${iotHubDeviceId}/connection-details`);
  }

  public createSwitch(switchEdit: SwitchEdit): Observable<Switch> {
    return this.http.post<Switch>(`${environment.data.baseUrl}/switches`, switchEdit);
  }

  public getDevicesData(tenant: number): Observable<Device[]> {
    return this.http.get<any[]>(`${environment.data.baseUrl}/devices`, { params: { tenantId: `${tenant}` } });
  }

  public getAllDevicesData(): Observable<Device[]> {
    return this.http.get<Device[]>(`${environment.data.baseUrl}/devices/all`);
  }

  public getDeviceConfigurationsByDeploymentId(deploymentId: number): Observable<DeviceConfiguration> {
    return this.http.get<DeviceConfiguration>(`${apiEndpoint}/deviceconfigurations/bydeploymentId/${deploymentId}`);
  }
}
