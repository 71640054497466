import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { containsWordFromList } from '@/shared/lib/core/utils/string.extension';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { NotificationStore } from '@/shared/lib/stores/notification.store';
import { UserStore } from '@/shared/lib/stores/user.store';

import { environment } from '../../../../../app/src/environments/environment';


export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const userStore = inject(UserStore);
  const notificationStore = inject(NotificationStore);
  const deploymentStore = inject(DeploymentStore);

  req = req.clone({
    setHeaders: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      tenantId: deploymentStore.selectedTenantId()?.toString() ?? '',
      deploymentId: deploymentStore.selectedDeploymentId()?.toString() ?? '',
    }
  })

  if (req.url.includes(environment.identity.baseUrl)) {
    const key = !containsWordFromList(req.url, environment.identity.unprotectedCalls) ? userStore?.token()?.token : '';

    req = req.clone({
      setHeaders: {
        'ocp-apim-subscription-key':  environment.identity.gatewayKey,
        Authorization: `Bearer ${key}`,
      },
    });
  } else if (req.url.includes(environment.data.baseUrl)) {
    const key = userStore?.token()?.token;
    const subscriptionKey = environment.data.gatewayKey;

    req = req.clone({
      setHeaders: {
        'ocp-apim-subscription-key': subscriptionKey,
        Authorization: `Bearer ${key}`,
        sub: userStore.userProfile()?.sub ?? '',
      },
    });
  } else {
    req = req.clone({
      setHeaders: {
        'ocp-apim-subscription-key': environment.data.gatewayKey,
      },
    });
  }

  notificationStore.setLoadingState(req, false);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
      finalize(() => {
        notificationStore.setLoadingState(req, true);
      })
  );
}
